import React, { useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Home from './pages/home';
import LiveStream from './pages/livestream';
import MatchStats from './pages/matchStats';
import leaveMatch from './actions/leaveMatch'; // Import the leaveMatch function
import { getSessionData, removeSessionData } from '../src/utils/session';

// Create a component to handle the useLocation logic
function NavigationHandler({ apiurl, matchId, handledLeave }) {
  const location = useLocation();

  useEffect(() => {
    const handleNavigationChange = () => {
      // Delay the check to avoid race conditions
      setTimeout(() => {
        if (location.pathname === '/') {
          console.log('You are on the home page!');
          removeSessionData('matchId');
          handledLeave(); // Leave the match if the user navigates to the home page
        } else {
          console.log('You are not on the home page.');
        }
      }, 1000);
    };

    // Call handleNavigationChange initially and on location change
    handleNavigationChange();

    // Add event listeners for navigation events (such as browser back/forward or touch events)
    window.addEventListener('popstate', handleNavigationChange);
    window.addEventListener('touchstart', handleNavigationChange);

    // Clean up event listeners on component unmount
    return () => {
      window.removeEventListener('popstate', handleNavigationChange);
      window.removeEventListener('touchstart', handleNavigationChange);
    };
  }, [location, handledLeave]); // Ensure the effect runs on location change or when handledLeave changes

  return null; // This component does not render any UI
}

function App() {
  const matchId = sessionStorage.getItem('matchId') || null; // Get matchId from sessionStorage
  const apiurl = process.env.REACT_APP_APIURL;

  // Callback to handle leaving the match
  const handledLeave = useCallback(() => {
    if (matchId) {
      leaveMatch(apiurl, matchId, sessionStorage.getItem('sessionId'))
        .then(() => {
          sessionStorage.removeItem('matchId'); // Remove matchId after leaving the match
        })
        .catch((error) => console.error('Error leaving match:', error));
    }
  }, [apiurl, matchId]);

  return (
    <Router>
      {/* NavigationHandler is responsible for handling location changes and leaving the match */}
      <NavigationHandler apiurl={apiurl} matchId={matchId} handledLeave={handledLeave} />

      {/* Routes for your application */}
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/:category/:page' element={<Home />} />
        <Route path='/live-stream/:matchId' element={<LiveStream />} />
        <Route path='/match-stats/' element={<MatchStats />} />
      </Routes>
    </Router>
  );
}

export default App;
