import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveCategory, setCurrentPage } from '../storage/store';
import { useNavigate } from 'react-router-dom';

const Category = () => {
  const dispatch = useDispatch();
  const activeCategory = useSelector(state => state.data.activeCategory);
  const navigate = useNavigate();

  const handleCategoryClick = (category) => {
    dispatch(setActiveCategory(category));
    dispatch(setCurrentPage(1));
    navigate(`/${category.toLowerCase()}/1`); // Navigate to the new category and reset to page 1
    console.log(`Clicked category: ${category}`);
  };

  return (
    <div className="matchCategory">
      <ul className="matchListCategory" id="listCategory">
        <li
          onClick={() => handleCategoryClick('all')}
          className={`matchFilter ${activeCategory.toLowerCase() === 'all' ? 'active' : ''}`}
          data-category="all"
        >
          <img decoding="async" src="/img/all_icon.svg" alt="All Icon" />ALL
        </li>
        <li
          onClick={() => handleCategoryClick('CRICKET')}
          className={`matchFilter ${activeCategory === 'CRICKET' ? 'active' : ''}`}
          data-category="CRICKET"
        >
          <img decoding="async" src="/img/cricket_icon.svg" alt="Cricket Icon" />CRICKET
        </li>
        <li
          onClick={() => handleCategoryClick('FOOTBALL')}
          className={`matchFilter ${activeCategory === 'FOOTBALL' ? 'active' : ''}`}
          data-category="FOOTBALL"
        >
          <img decoding="async" src="/img/football_icon.svg" alt="Football Icon" />FOOTBALL
        </li>
        <li
          onClick={() => handleCategoryClick('BASKETBALL')}
          className={`matchFilter ${activeCategory === 'BASKETBALL' ? 'active' : ''}`}
          data-category="BASKETBALL"
        >
          <img decoding="async" src="/img/basketball_icon.svg" alt="Basketball Icon" />BASKETBALL
        </li>
        <li
          onClick={() => handleCategoryClick('TENNIS')}
          className={`matchFilter ${activeCategory === 'TENNIS' ? 'active' : ''}`}
          data-category="TENNIS"
        >
          <img decoding="async" src="/img/tennis_icon.svg" alt="Tennis Icon" />TENNIS
        </li>
        <li
          onClick={() => handleCategoryClick('ESPORTS')}
          className={`matchFilter ${activeCategory === 'ESPORTS' ? 'active' : ''}`}
          data-category="ESPORTS"
        >
          <img decoding="async" src="/img/esport_icon.svg" alt="Esport Icon" />ESPORTS
        </li>
      </ul>
    </div>
  );
};

export default Category;
