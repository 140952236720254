// src/utils/session.js

// Function to set session data
export const setSessionData = (key, value) => {
    sessionStorage.setItem(key, value);
};

// Function to get session data
export const getSessionData = (key) => {
    return sessionStorage.getItem(key);
};

// Function to remove session data
export const removeSessionData = (key) => {
    sessionStorage.removeItem(key);
};
