import axios from 'axios';
import { setSessionData } from '../utils/session'; // Utility to handle sessionStorage

const joinMatch = async (apiurl, matchId, sessionId) => {
    const startTime = new Date().toISOString(); // ISO format time string
    setSessionData('startTime', startTime);
    
    try {
        await axios.post(`${apiurl}/match-stats/join-match/${matchId}`, {
            sessionId,
            startTime,
        });
    } catch (error) {
        console.error('Error joining match:', error);
    }
};

export default joinMatch;