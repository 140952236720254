// src/actions/fetchViewerCount.js
import axios from 'axios';

const fetchViewerCount = async (apiurl, matchId, setActiveUsers) => {
    try {
        const response = await axios.get(`${apiurl}/match-stats/viewer-count/${matchId}`);
        setActiveUsers(response.data.viewerCount);
    } catch (error) {
        console.error('Error fetching viewer count:', error);
    }
};

export default fetchViewerCount;
