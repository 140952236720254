import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setLoading, setData, setError } from '../storage/store';
import { getMatches } from "../service/getMatches";
import '../css/style.css';
import Caterory from "../component/catergory";
import Matches from "../component/matches";


const Home = () => {
    const dispatch = useDispatch();


    useEffect(() => {
        const fetchData = async () => {
            dispatch(setLoading(true));
            try {
                const response = await getMatches();
                if (response.length === null) {
                    throw new Error('Network Response was not OK');
                }
                const data = await response;
                dispatch(setData(data)); // Dispatch action to set data in Redux store
            } catch (error) {
                dispatch(setError(error.toString()));
            }
        };

        fetchData();
        
    }, [dispatch]);

    return (
        <>
            <div className="matches">
                <Caterory />
                <Matches />
            </div>
        </>
    );
};

export default Home;
