export const getMatches = async () => {
    const tokenkey = process.env.REACT_APP_TOKENKEY;
    const apiurl = process.env.REACT_APP_APIURL;
  
    try {
      const response = await fetch(`${apiurl}/matches?key=${tokenkey}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
    //  console.log(data);
      return data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };