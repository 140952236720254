// src/utils/getSessionId.js
import { v4 as uuidv4 } from 'uuid';

const getSessionId = () => {
    let sessionId = sessionStorage.getItem('sessionId');
    if (!sessionId) {
        sessionId = uuidv4();
        sessionStorage.setItem('sessionId', sessionId);
    }
    return sessionId;
};

export default getSessionId;
