
export const getLiveStreamByMatchid = async (matchID) => {
    const tokenkey = process.env.REACT_APP_TOKENKEY;
    const apiurl = process.env.REACT_APP_APIURL;
    try {
        const req = `${apiurl}/liveUrl/${matchID}?key=${tokenkey}`;
        const response = await fetch(req);
        const data = await response.json();
        return data.H5LINKROW;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error; // Rethrow the error so it can be handled by the caller
    }
};