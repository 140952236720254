import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Typography, Button, Select, MenuItem, FormControl, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import { LineChart } from '@mui/x-charts/LineChart';
import '../css/matchstats.css';

const MatchStats = () => {
    const [matchStats, setMatchStats] = useState({});
    const [matchStatsList, setMatchStatsList] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(12);
    const [matchStatsByEnddate, setmatchStatsByEnddate] = useState([]);
    const [sessionStats, setsessionStats] = useState({});
    const [availableMonths, setAvailableMonths] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState(null);

    const apiurl = process.env.REACT_APP_APIURL;

    // Fetch available months for the dropdown
    useEffect(() => {
        const fetchAvailableMonths = async () => {
            try {
                const response = await axios.get(`${apiurl}/match-stats/available-months`, { timeout: 6000 });
                const months = response.data.data || [];
                setAvailableMonths(months);

                if (months.length > 0) {
                    setSelectedMonth(months[0]);
                }

                setLoading(false);
            } catch (err) {
                console.error('Error fetching available months:', err);
                setError('Failed to load available months');
                setLoading(false);
            }
        };

        fetchAvailableMonths();
    }, [apiurl]);

    // Fetch data (match stats, session stats, and enddate stats) based on selectedMonth
    useEffect(() => {
        if (selectedMonth) {
            const fetchMatchStatsEndDate = async () => {
                try {
                    const response = await axios.get(`${apiurl}/match-stats/match-stats-by-enddate/${selectedMonth}`, { timeout: 6000 });
                    setmatchStatsByEnddate(response.data.data || []);
                    setLoading(false);
                } catch (err) {
                    console.error('Error fetching match stats by end date:', err);
                    setError('Failed to load match stats by end date');
                    setLoading(false);
                }
            };

            const fetchSessionStats = async () => {
                try {
                    const response = await axios.get(`${apiurl}/match-stats/session-stats/${selectedMonth}`, { timeout: 6000 });
                    setsessionStats(response.data);
                    setLoading(false);
                } catch (err) {
                    console.error('Error fetching session stats:', err);
                    setError('Failed to load session stats');
                    setLoading(false);
                }
            };

            const fetchMatchStats = async () => {
                try {
                    const response = await axios.get(`${apiurl}/match-stats/match-stats/${selectedMonth}`, { timeout: 6000 });
                    
                    setMatchStats(response.data.data);
                    setLoading(false);
                } catch (err) {
                    console.error('Error fetching match stats:', err);
                    setError('Failed to load match stats');
                    setLoading(false);
                }
            };


            const fetchMatchStatsList = async () => {
                try {
                    const response = await axios.get(`${apiurl}/match-stats/match-stats-list/${selectedMonth}`, { timeout: 6000 });
                    console.log("stats here");
                    console.log(response.data.data);
                    setMatchStatsList(response.data.data);
                    setLoading(false);
                } catch (err) {
                    console.error('Error fetching match stats:', err);
                    setError('Failed to load match stats');
                    setLoading(false);
                }
            };

            fetchMatchStatsEndDate();
            fetchSessionStats();
            fetchMatchStats();
            fetchMatchStatsList();
        }
    }, [apiurl, selectedMonth]);

    if (loading) {
        return <div className='loading'><div className="box-spinner"></div></div>;
    }

    if (error) {
        return <p>{error}</p>;
    }

    const xdateDate = Array.isArray(matchStatsByEnddate)
        ? matchStatsByEnddate.map(item => {
              const date = new Date(item.endDate);
              return date.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' });
          })
        : [];

    const yViewersCount = Array.isArray(matchStatsByEnddate) ? matchStatsByEnddate.map(item => item.sessionCount) : [];

    const matchEntries = Object.entries(matchStats);
    const totalPages = Math.ceil(matchEntries.length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = matchEntries.slice(indexOfFirstItem, indexOfLastItem);

    const handleSelectPage = (eventKey) => {
        setCurrentPage(Number(eventKey));
    };

    const handleSelectItemsPerPage = (event) => {
        setItemsPerPage(Number(event.target.value));
        setCurrentPage(1);
    };

    const nextPage = () => {
        setCurrentPage(prev => (prev < totalPages ? prev + 1 : prev));
    };

    const prevPage = () => {
        setCurrentPage(prev => (prev > 1 ? prev - 1 : prev));
    };

    const handleMonthChange = (event) => {
        setSelectedMonth(event.target.value);
    };

    // Function to convert matchStats to CSV format
    const downloadCSV = () => {
        const headers = ["Match ID",  "Team", "League", "Sports Type", "Viewer Count", "Total Time Spent"];  // Add new headers
        const csvRows = [headers.join(",")];  // Start the CSV with the headers
    
        console.log('match list:', matchStatsList);  // Log the match stats list to ensure it's correct
    
        // Iterate over the keys of matchStatsList to create rows for CSV
        Object.keys(matchStatsList).forEach(match_id => {
            const match = matchStatsList[match_id];
            const row = [
                match_id,  // Use match_id from the keys
                match.team || "N/A",  // Access team, handle null values
                match.league || "N/A",  // Access league, handle null values
                match.sportstype || "N/A" , // Access sportstype, handle null values
                match.viewerCount,  // Access viewerCount
                match.totalTimeSpent  // Access totalTimeSpent
               
            ].join(",");
            csvRows.push(row);  // Add the row to CSV content
        });
    
        // Create CSV content
        const csvContent = csvRows.join("\n");
        const blob = new Blob([csvContent], { type: "text/csv" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `match-stats-${selectedMonth}.csv`);  // File name with selected month
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    
    

    return (
        <div className='match-stats'>
            <div className='match-graph'>
                <div className='match-date'>
                    {/* Download button for match stats */}
                   
                    <div className='select-monthly'>
                        <Typography color='#fff'>Data for the month of</Typography>
                        <Select
                            labelId="month-select-label"
                            value={selectedMonth}
                            onChange={handleMonthChange}
                            sx={{ backgroundColor: "#fff", padding: "1px" }}
                        >
                            {availableMonths.map((monthYear, index) => (
                                <MenuItem key={index} value={monthYear}>
                                    {monthYear}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                    <div className='download-button'>
                        <Button variant="contained" onClick={downloadCSV}>Download Match Stats</Button>
                    </div>
                </div>
                <div className='session-statistic'>
                    <div className='stats'>
                        <span className='count'>{sessionStats.sessionCount}</span>
                        <span className='label'>Total views</span>
                    </div>
                    <div className='stats'>
                        <span className='count'>{(sessionStats.totalTimeSpentSeconds / 3600).toFixed(2)}</span>
                        <span className='label'>Total Time Spent (hr)</span>
                    </div>
                    <div className='stats'>
                        <span className='count'>{sessionStats.uniqueSessionCount}</span>
                        <span className='label'>Unique viewers</span>
                    </div>
                    <div className='stats'>
                        <span className='count'>{sessionStats.timeSpentPercentage}</span>
                        <span className='label'>Time spent / 24 hours</span>
                    </div>
                </div>
                <div className='match-linegraph'>
                    <LineChart
                        width={650}
                        height={300}
                        series={[
                            { data: yViewersCount, label: 'Views' },
                        ]}
                        xAxis={[{ scaleType: 'point', data: xdateDate }]}
                    />
                </div>
            </div>
            <div className='title-header'>
                <Typography variant="h2" color="white" sx={{ fontSize: '1.8rem', fontWeight: 700 }}>All Matches</Typography>
                <div className='optionPerItem'>
                     
                    <div className='itemsperpage'>
                        Items per page
                        <FormControl sx={{ minWidth: 120, marginLeft: 2 }}>
                            <Select
                                labelId="items-per-page-label"
                                value={itemsPerPage}
                                onChange={handleSelectItemsPerPage}
                                sx={{ backgroundColor: "#fff" }}
                            >
                                <MenuItem value={12}>12</MenuItem>
                                <MenuItem value={24}>24</MenuItem>
                                <MenuItem value={48}>48</MenuItem>
                                <MenuItem value={100}>100</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                   
                </div>
            </div>
            <div className='stats-container'>
                <div className='stats-list'>

                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Match ID</TableCell>
                                <TableCell>Match Name</TableCell>
                                <TableCell>League</TableCell>
                                <TableCell>Sports Type</TableCell>
                                <TableCell sx={{textAlign:'center'}}>Total Time Spent (seconds)</TableCell>
                                <TableCell sx={{textAlign:'center'}}>Viewer Count</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {currentItems.length >= 0 ? (currentItems.map(([matchId, stats]) => 
                            {
                                return (
                                    <>
                                   
                                    <TableRow key={matchId}>
                                        <TableCell>{matchId}</TableCell>
                                        <TableCell>{stats?.team || 'N/A'}</TableCell>
                                        <TableCell>{stats?.league || 'N/A'}</TableCell>
                                        <TableCell>{stats?.sportstype || 'N/A'}</TableCell>
                                        <TableCell sx={{textAlign:'center'}}>{stats?.totalTimeSpent}</TableCell>
                                        <TableCell sx={{textAlign:'center'}}>{stats?.viewerCount}</TableCell>
                                    </TableRow>
                                    </>
                                )
                            }
                               
                            )
                        ) : (
                                <TableRow>
                                    <TableCell colSpan="3">No match data available</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                                    
                    </Table>
                </div>
            </div>

            <div className='stats-pagination'>
                <Button variant="contained" onClick={prevPage} disabled={currentPage === 1}>Prev</Button>
                <FormControl sx={{ minWidth: 120 }}>
                    <Select
                        value={currentPage}
                        onChange={(e) => handleSelectPage(e.target.value)}
                        displayEmpty
                        inputProps={{ 'aria-label': 'page' }}
                        sx={{ backgroundColor: "#fff" }}
                    >
                        {Array.from({ length: totalPages }, (_, index) => (
                            <MenuItem key={index + 1} value={index + 1}>
                                Page {index + 1}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Button variant="contained" onClick={nextPage} disabled={currentPage === totalPages}>Next</Button>
            </div>

            
        </div>
    );
};

export default MatchStats;
